/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


// Garlic
/* Garlicjs dist/garlic.min.js build version 1.2.4 http://garlicjs.org */
!function(b){var h=function(a){this.defined="undefined"!==typeof localStorage};h.prototype={constructor:h,get:function(a,b){return localStorage.getItem(a)?localStorage.getItem(a):"undefined"!==typeof b?b:null},has:function(a){return localStorage.getItem(a)?!0:!1},set:function(a,b,d){"string"===typeof b&&(""===b?this.destroy(a):localStorage.setItem(a,b));return"function"===typeof d?d():!0},destroy:function(a,b){localStorage.removeItem(a);return"function"===typeof b?b():!0},clean:function(a){for(var b=
  localStorage.length-1;0<=b;b--)"undefined"===typeof Array.indexOf&&-1!==localStorage.key(b).indexOf("garlic:")&&localStorage.removeItem(localStorage.key(b));return"function"===typeof a?a():!0},clear:function(a){localStorage.clear();return"function"===typeof a?a():!0}};var k=function(a,b,d){this.init("garlic",a,b,d)};k.prototype={constructor:k,init:function(a,c,d,e){this.type=a;this.$element=b(c);this.options=this.getOptions(e);this.storage=d;this.path=this.options.getPath(this.$element)||this.getPath();
  this.parentForm=this.$element.closest("form");this.$element.addClass("garlic-auto-save");this.expiresFlag=!this.options.expires?!1:(this.$element.data("expires")?this.path:this.getPath(this.parentForm))+"_flag";this.$element.on(this.options.events.join("."+this.type+" "),!1,b.proxy(this.persist,this));if(this.options.destroy)b(this.parentForm).on("submit reset",!1,b.proxy(this.destroy,this));this.retrieve()},getOptions:function(a){return b.extend({},b.fn[this.type].defaults,a,this.$element.data())},
  persist:function(){this.val!==this.getVal()&&(this.val=this.getVal(),this.options.expires&&this.storage.set(this.expiresFlag,((new Date).getTime()+1E3*this.options.expires).toString()),this.storage.set(this.path,this.getVal()),this.options.onPersist(this.$element,this.getVal()))},getVal:function(){return!this.$element.is("input[type=checkbox]")?this.$element.val():this.$element.prop("checked")?"checked":"unchecked"},retrieve:function(){if(this.storage.has(this.path)){if(this.options.expires){var a=
    (new Date).getTime();if(this.storage.get(this.expiresFlag)<a.toString()){this.storage.destroy(this.path);return}this.$element.attr("expires-in",Math.floor((parseInt(this.storage.get(this.expiresFlag))-a)/1E3))}a=this.storage.get(this.path);if(this.options.conflictManager.enabled&&this.detectConflict())return this.conflictManager();if(this.$element.is("input[type=radio], input[type=checkbox]")){if("checked"===a||this.$element.val()===a)return this.$element.attr("checked",!0);"unchecked"===a&&this.$element.attr("checked",
    !1)}else this.$element.val(a),this.$element.trigger("input"),this.options.onRetrieve(this.$element,a)}},detectConflict:function(){var a=this;if(this.$element.is("input[type=checkbox], input[type=radio]"))return!1;if(this.$element.val()&&this.storage.get(this.path)!==this.$element.val()){if(this.$element.is("select")){var c=!1;this.$element.find("option").each(function(){0!==b(this).index()&&(b(this).attr("selected")&&b(this).val()!==a.storage.get(this.path))&&(c=!0)});return c}return!0}return!1},
  conflictManager:function(){if("function"===typeof this.options.conflictManager.onConflictDetected&&!this.options.conflictManager.onConflictDetected(this.$element,this.storage.get(this.path)))return!1;this.options.conflictManager.garlicPriority?(this.$element.data("swap-data",this.$element.val()),this.$element.data("swap-state","garlic"),this.$element.val(this.storage.get(this.path))):(this.$element.data("swap-data",this.storage.get(this.path)),this.$element.data("swap-state","default"));this.swapHandler();
    this.$element.addClass("garlic-conflict-detected");this.$element.closest("input[type=submit]").attr("disabled",!0)},swapHandler:function(){var a=b(this.options.conflictManager.template);this.$element.after(a.text(this.options.conflictManager.message));a.on("click",!1,b.proxy(this.swap,this))},swap:function(){var a=this.$element.data("swap-data");this.$element.data("swap-state","garlic"===this.$element.data("swap-state")?"default":"garlic");this.$element.data("swap-data",this.$element.val());b(this.$element).val(a)},
  destroy:function(){this.storage.destroy(this.path)},remove:function(){this.remove();this.$element.is("input[type=radio], input[type=checkbox]")?b(this.$element).prop("checked",!1):this.$element.val("")},getPath:function(a){"undefined"===typeof a&&(a=this.$element);if(this.options.getPath(a))return this.options.getPath(a);if(1!=a.length)return!1;for(var c="",d=a.is("input[type=checkbox]"),e=a;e.length;){a=e[0];var f=a.nodeName;if(!f)break;var f=f.toLowerCase(),e=e.parent(),g=e.children(f);if(b(a).is("form, input, select, textarea")||
    d)if(f+=b(a).attr("name")?"."+b(a).attr("name"):"",1<g.length&&!b(a).is("input[type=radio]")&&(f+=":eq("+g.index(a)+")"),c=f+(c?">"+c:""),"form"==a.nodeName.toLowerCase())break}return"garlic:"+document.domain+(this.options.domain?"*":window.location.pathname)+">"+c},getStorage:function(){return this.storage}};b.fn.garlic=function(a,c){function d(c){var d=b(c),g=d.data("garlic"),h=b.extend({},e,d.data());if(("undefined"===typeof h.storage||h.storage)&&"password"!==b(c).attr("type"))if(g||d.data("garlic",
    g=new k(c,f,h)),"string"===typeof a&&"function"===typeof g[a])return g[a]()}var e=b.extend(!0,{},b.fn.garlic.defaults,a,this.data()),f=new h,g=!1;if(!f.defined)return!1;this.each(function(){b(this).is("form")?b(this).find(e.inputs).each(function(){b(this).is(e.excluded)||(g=d(b(this)))}):b(this).is(e.inputs)&&!b(this).is(e.excluded)&&(g=d(b(this)))});return"function"===typeof c?c():g};b.fn.garlic.Constructor=k;b.fn.garlic.defaults={destroy:!0,inputs:"input, textarea, select",excluded:'input[type="file"], input[type="hidden"], input[type="submit"]',
  events:"DOMAttrModified textInput input change click keypress paste focus".split(" "),domain:!1,expires:!1,conflictManager:{enabled:!1,garlicPriority:!0,template:'<span class="garlic-swap"></span>',message:"This is your saved data. Click here to see default one",onConflictDetected:function(a,b){return!0}},getPath:function(a){},onRetrieve:function(a,b){},onPersist:function(a,b){}};b(window).on("load",function(){b('[data-persist="garlic"]').each(function(){b(this).garlic()})})}(window.jQuery||window.Zepto);
// End Garlic





function throttle(callback, delay) {
  var last;
  var timer;
  return function () {
    var context = this;
    var now = +new Date();
    var args = arguments;
    if (last && now < last + delay) {
      // le délai n'est pas écoulé on reset le timer
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        callback.apply(context, args);
      }, delay);
    } else {
      last = now;
      callback.apply(context, args);
    }
  };
}

function debounce(callback, delay){
  var timer;
  return function(){
    var args = arguments;
    var context = this;
    clearTimeout(timer);
    timer = setTimeout(function(){
      callback.apply(context, args);
    }, delay)
  }
}


Math.radians = function(degrees) {
  return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
Math.degrees = function(radians) {
  return radians * 180 / Math.PI;
};

function is_touch_device() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Scroll to content
        $(document).on('click', 'a[href="#scroll-to-content"]', function(e){
          e.preventDefault();
          $('html, body').animate({
            scrollTop: $('.page-content').first().offset().top
          }, 600);
        });

        $('.main-menu').on('click', '> li > a:not(.hover)', function(e){
          if(is_touch_device() && $(this).next('.sub-menu').length != 0){
            e.preventDefault();
            $(this).toggleClass('hover')
          }
        });

        // Save bootstrap tabs
        $('[data-save-tabs] a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          var savedTabs = JSON.parse(localStorage.getItem('savedTabs'));
          if (typeof savedTabs !== 'object' || !savedTabs){
            savedTabs = {};
          }
          savedTabs[$(this).closest('[data-save-tabs]').data('save-tabs')] = $(this).attr('href');
          localStorage.setItem('savedTabs', JSON.stringify(savedTabs));
        });

        $('[role="tablist"]').each(function (idx, elem) {
          var savedTabs = JSON.parse(localStorage.getItem('savedTabs'));
          if (typeof savedTabs === 'object'){
            for(var index in savedTabs) {
              if ($(this).data('save-tabs') == index){
                $('[href="' + savedTabs[index] + '"]').tab('show');
              }
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_outils': {
      init: function() {

        $('.input-group-addon').on('click', function(e){
          $(this).siblings('input').focus();
        });

        $('.tabs-tools form input[id]').each(function(index, val){
          $(this).attr('name', $(this).attr('id'));
        });

        $('[href="#menu-outils"], [href="#close-menu-outils"]').on('click', function(e){
          e.preventDefault();
          $(".tabs-tools-buttons-wrapper").toggleClass('hide');
        });

        $(".tabs-tools-buttons-wrapper a").on('click', function(e){
          $(".tabs-tools-buttons-wrapper").addClass('hide');
          $('html, body').animate({
            scrollTop: $('.page-content').first().offset().top
          }, 1);
        });

        function nextTabTool(){
          var $active = $('[role="tablist"] .active');
          if ($active.length != 0){
            if ($active.next('li').length != 0){
              $active.next('li').find('a').tab('show');
            }else{
              $('[role="tablist"] li').first().find('a').tab('show');
            }
          }else{
            $('[role="tablist"] li').first().find('a').tab('show');
          }
        }

        function prevTabTool(){
          var $active = $('[role="tablist"] .active');

          if ($active.length != 0){
            if ($active.prev('li').length != 0){
              $active.prev('li').find('a').tab('show');
            }else{
              $('[role="tablist"] li').last().find('a').tab('show');
            }
          }else{
            $('[role="tablist"] li').last().find('a').tab('show');
          }
        }

        $('[href="#next-tool"]').on('click', function(e){
          e.preventDefault();
          nextTabTool();
        });

        $('[href="#prev-tool"]').on('click', function(e){
          e.preventDefault();
          prevTabTool();
        });

        $(".input-result").prop( "disabled", true );

        function form_stretchtdo_stretchingtd(){
          var min = null, max = null, val;
          $('.stretchtdo_width_input').each(function(index, val){
            val = parseFloat($(this).val());
            if (isNaN(val) || val <= 0){ return; }
            if(!min){ min = val }
            if(!max){ max = val }
            min = Math.min(min, val);
            max = Math.max(max, val);
          });
          return (max/min);
        }
        function form_stretchtdo_relaxation(){
          var min = null, max = null, val, last;
          $('.stretchtdo_width_input').each(function(index, val){
            val = parseFloat($(this).val());
            if (isNaN(val) || val <= 0){ return; }
            if(!min){ min = val }
            if(!max){ max = val }
            min = Math.min(min, val);
            max = Math.max(max, val);
            last = val;
          });
          return ((max - last) / max) * 100;
        }
        function form_stretchtdo_time(){
          var sum = 0;
          $('.stretchtdo_length_input').each(function(index, val){
            val = parseFloat($(this).val());
            if (isNaN(val) || val <= 0){ return; }
            sum = sum + val;
          });
          return sum * 60 / parseFloat($('#form_stretchtdo_line_speed').val());
        }


        var rounds = 3;
        var updateForm = throttle(function($form){
          //var $form = $(this);
          $form.find('[data-formula]').each(function(index, el){
            var formula = $(el).data('formula');
            var formattedFormula = formula.replace(/\{\{([A-Z]+[0-9]+)\}\}/gi, "parseFloat($$form.find('[data-cell=\"$1\"]').val())");
            var result = eval(formattedFormula);
            var precision = $(el).data('precision');
            if(typeof precision === 'undefined'){
              precision = 2;
            }
            if(isNaN(result)){
              $(el).val("0");
            }else{
              $(el).val(result.toFixed(precision));
            }
          });
          if (rounds > 0 && rounds <= 3){
            rounds = rounds - 1;
            //throttle(updateForm($form), 300);
            setTimeout(function(){
              updateForm($form)
            }, 300)
          }else{
            rounds = 3;
          }
        }, 300);


        $('.tabs-tools form').on('keyup change submit', function(e){
          updateForm($(this));
        });

        $('form').garlic({
          onRetrieve: function(elem){
            updateForm($(elem).closest('form'));
          }
        });
        $('.tabs-tools-buttons-wrapper a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          $($(this).attr('href')).find('form').trigger('change');
        })
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
